import { HDWYGTypes } from '../App';
import { getColorFromType } from '../utils';

interface HomeProps {
    changeActive: (type: HDWYGTypes) => void;
}

export const Home = ({ changeActive }: HomeProps) => {
    return (
        <div className="flex flex-col items-start justify-center px-6 py-14">
            <h1 className="text-brand-bold text-4xl">How deep will you go?</h1>
            <p className="text-brand-regular text-lg">
                Connections that feel good is the north star. This is the rocket
                ship to get there. Original is the perfect game of emotional
                connection.
            </p>
            {Object.keys(HDWYGTypes).map((type) => (
                <div
                    key={type}
                    className={`text-brand-regular my-2 flex h-36 w-full cursor-pointer items-center justify-center rounded-xl text-2xl text-white`}
                    style={{
                        backgroundColor: getColorFromType(
                            HDWYGTypes[type as keyof typeof HDWYGTypes],
                        ),
                    }}
                    onClick={() => {
                        changeActive(
                            HDWYGTypes[type as keyof typeof HDWYGTypes],
                        );
                    }}
                >
                    {type.toUpperCase()}
                </div>
            ))}
        </div>
    );
};
