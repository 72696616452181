import { HDWYGTypes } from './App';

export function getColorFromType(type: HDWYGTypes) {
    switch (type) {
        case HDWYGTypes.Close:
            return '#50C878';
        case HDWYGTypes.Closer:
            return '#F4C430';
        case HDWYGTypes.Closest:
            return '#EE4B2B';
    }
}
