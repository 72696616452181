import TinderCard from 'react-tinder-card';
import { HDWYGTypes } from '../App';
import { getColorFromType } from '../utils';

interface CardProps {
    text: string;
    title: HDWYGTypes;
    handleSwipe: () => void;
}

export const Card = ({ text, title, handleSwipe }: CardProps) => {
    const onSwipe = (direction: string) => {
        console.log('You swiped: ' + direction);
        handleSwipe();
    };

    const onCardLeftScreen = (myIdentifier: string) => {
        console.log(myIdentifier + ' left the screen');
    };

    return (
        <TinderCard
            onSwipe={onSwipe}
            onCardLeftScreen={() => onCardLeftScreen('fooBar')}
            className="swipe"
        >
            <div className="relative flex h-[90vh] w-[90vw] flex-col items-center justify-between rounded-lg border bg-[#FAFAFA] px-8 py-2">
                <div
                    className={`text-brand-regular text-xl`}
                    style={{ color: getColorFromType(title) }}
                >
                    {title.toUpperCase()}
                </div>
                <p className="text-brand-regular text-center text-2xl">
                    {text}
                </p>
                <div />
            </div>
        </TinderCard>
    );
};
