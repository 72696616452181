import { useEffect, useState } from 'react';
import { Home } from './components/Home';
import { LastOpen } from './components/LastOpen';
import { Stack } from './components/Stack';

export enum HDWYGTypes {
    Close = 'close',
    Closer = 'closer',
    Closest = 'closest',
    None = 'none',
}

interface HDWYG {
    [HDWYGTypes.Close]: string[];
    [HDWYGTypes.Closer]: string[];
    [HDWYGTypes.Closest]: string[];
    [HDWYGTypes.None]: null;
}

function App() {
    const [active, setActive] = useState<HDWYGTypes>(HDWYGTypes.None);
    const hdwyg: HDWYG = require('./assets/content.json');
    let lastOpen = window.localStorage.getItem('lastOpen') as HDWYGTypes;

    useEffect(() => {
        lastOpen = window.localStorage.getItem('lastOpen') as HDWYGTypes;
        console.log(lastOpen)
    }, [active])

    function changeActive(type: HDWYGTypes) {
        setActive(type);
        if (type !== HDWYGTypes.None) {
            window.localStorage.setItem('lastOpen', type.toString());
        }
    }

    return (
        <div className="h-screen w-screen overflow-hidden bg-[#F7F3EF]">
            {lastOpen !== HDWYGTypes.None && lastOpen !== null && active === HDWYGTypes.None ? (
                <LastOpen title={lastOpen} open={() => changeActive(lastOpen)} />
            ) : <></>}
            {active !== HDWYGTypes.None ? (
                <Stack
                    changeActive={changeActive}
                    title={active}
                    quotes={hdwyg[active]}
                />
            ) : (
                <Home changeActive={changeActive} />
            )}
        </div>
    );
}

export default App;
