import { HDWYGTypes } from '../App';

interface LastOpenProps {
    title: HDWYGTypes;
    open: (title: HDWYGTypes) => void;
}

export const LastOpen = ({ title, open }: LastOpenProps) => {
    return (
        <div onClick={() => open(title)} className="flex flex-row justify-between items-center bg-[#A3BFFE] px-4 py-2 rounded-md bg-opacity-60 border-2 border-[#A3BFFE] m-4">
            <p>Last opened: {title}</p>
            <p className="font-bold">Open</p>
        </div>
    );
};
