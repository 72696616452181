import { useEffect, useState } from 'react';
import { HDWYGTypes } from '../App';
import { Card } from './Card';

interface StackProps {
    title: HDWYGTypes;
    quotes: string[];
    changeActive: (state: HDWYGTypes) => void;
}

export const Stack = ({ title, quotes, changeActive }: StackProps) => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const progress = parseInt(window.localStorage.getItem(title.toLowerCase()) ?? '1');
        setIndex(progress - 1);
    }, [])

    function handleSwipe() {
        const newIndex = index + 1;
        if (newIndex === quotes.length - 1) {
            changeActive(HDWYGTypes.None);
        }
        setIndex(newIndex);
        window.localStorage.setItem(title.toLowerCase(), (newIndex + 1).toString());
    }

    return (
        <div className="flex h-full w-full flex-col justify-start items-center">
            <p className="mb-2">{index + 1} / {quotes.length}</p>
            <div className="flex justify-center">
                {quotes.map((quote, i) => (
                    <Card
                        key={i}
                        text={quote}
                        title={title}
                        handleSwipe={handleSwipe}
                    />
                ))}
            </div>
        </div>
    );
};
